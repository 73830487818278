import { render, staticRenderFns } from "./widget.vue?vue&type=template&id=53d97cb1&scoped=true&"
import script from "./widget.vue?vue&type=script&lang=js&"
export * from "./widget.vue?vue&type=script&lang=js&"
import style0 from "./widget.vue?vue&type=style&index=0&id=53d97cb1&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53d97cb1",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ElementsTicketSelector: require('/home/ticketshoplv/public_html/nervy.tour.ticketshop/components/elements/TicketSelector.vue').default,ElementsSeatmap: require('/home/ticketshoplv/public_html/nervy.tour.ticketshop/components/elements/Seatmap.vue').default,CartBar: require('/home/ticketshoplv/public_html/nervy.tour.ticketshop/components/Cart/Bar.vue').default})
